var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Draggable',{class:[
    'layout-elements layout-elements--summary',
    {
      'drag-target': _vm.isTargetDropZone,
    } ],attrs:{"group":"elements","disabled":!_vm.isShowingLayoutEditUI,"handle":".drag-handle","move":_vm.moveFn},on:{"end":_vm.endFn},model:{value:(_vm.layoutElements),callback:function ($$v) {_vm.layoutElements=$$v},expression:"layoutElements"}},[(!_vm.layoutElements.length)?_c('div',{staticClass:"text-xs-center placeholder"},[_vm._v("Drag Items Here")]):_vm._l((_vm.layoutElements),function(element,e){return [_c('div',{key:element.id || e,staticClass:"layout-element--draggable layout-element--summary"},[(element.id)?_c('WithLayoutElement',{key:element.id,attrs:{"element-id":element.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var isElementLoading = ref.isElementLoading;
  var theElement = ref.theElement;
return [(!isElementLoading && theElement)?[_c('LayoutElementSummary',{attrs:{"element":theElement}})]:_vm._e()]}}],null,true)}):_c('LayoutElementSummary',{attrs:{"element":element}})],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }