<template>
  <component
    :is="componentType"
    v-if="componentType"
    :element="element"
    :ratio="ratio"
    :is-summary-view="true"
    :class="`layout-element--${element.type}`"
  />
</template>

<script>
import LayoutElementTypeText from './LayoutElementTypeText'
import LayoutElementTypeImage from './LayoutElementTypeImage'
import LayoutElementTypeRelated from './LayoutElementTypeRelated'

export default {
  name: 'LayoutElement',
  components: {
    LayoutElementTypeText,
    LayoutElementTypeImage,
    LayoutElementTypeRelated,
  },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {},
    },
    ratio: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {}
  },
  computed: {
    componentType() {
      switch (this.element.type) {
        case 'text':
          return 'LayoutElementTypeText'
        case 'image':
          return 'LayoutElementTypeImage'
        case 'related':
          return 'LayoutElementTypeRelated'
      }

      return null
    },
  },
}
</script>
