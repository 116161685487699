<template>
  <Draggable
    v-model="layoutElements"
    :class="[
      'layout-elements layout-elements--summary',
      {
        'drag-target': isTargetDropZone,
      },
    ]"
    group="elements"
    :disabled="!isShowingLayoutEditUI"
    handle=".drag-handle"
    :move="moveFn"
    @end="endFn"
  >
    <div v-if="!layoutElements.length" class="text-xs-center placeholder"
      >Drag Items Here</div
    >

    <template v-for="(element, e) in layoutElements" v-else>
      <div
        :key="element.id || e"
        class="layout-element--draggable layout-element--summary"
      >
        <WithLayoutElement
          v-if="element.id"
          v-slot="{ isElementLoading, theElement }"
          :key="element.id"
          :element-id="element.id"
        >
          <template v-if="!isElementLoading && theElement">
            <LayoutElementSummary :element="theElement" />
          </template>
        </WithLayoutElement>
        <LayoutElementSummary v-else :element="element" />
      </div>
    </template>
  </Draggable>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _get from 'lodash/get'
import Draggable from 'vuedraggable'
import LayoutElementSummary from './LayoutElementSummary'
import WithLayoutElement from '@dataProviders/WithLayoutElement'

export default {
  name: 'LayoutElementPicklist',
  components: {
    Draggable,
    LayoutElementSummary,
    WithLayoutElement,
  },
  props: {
    elements: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('layoutEditor', [
      'isShowingLayoutEditUI',
      'targetDropZoneId',
    ]),
    layoutElements: {
      get() {
        return this.elements
      },
      set(list) {
        this.$emit('changed', list)
      },
    },
    isTargetDropZone() {
      return this.regionId === this.targetDropZoneId
    }, // isTargetDropZone
  }, // computed
  methods: {
    ...mapActions('layoutEditor', ['setTargetDropZone']),
    moveFn(evt) {
      let evtTargetId = _get(evt, 'relatedContext.element.id')
      if (evtTargetId) {
        this.setTargetDropZone(evtTargetId)
      } else if (_get(evt, 'relatedContext.component.$parent.regionId')) {
        this.setTargetDropZone(evt.relatedContext.component.$parent.regionId)
      }
    }, // moveFn
    endFn() {
      this.setTargetDropZone(null)
    }, // endFn
  }, // methods
}
</script>

<style lang="scss" scoped>
.layout-elements {
  height: 100%;
  .layout-element--draggable {
    cursor: grab;
  }
  .layout-element--summary {
    user-select: none;
  }
}
.layout-elements--summary {
  .sortable-ghost.sortable-chosen:not(.layout-element--summary) {
    color: white !important;
    background-color: red !important;
    * {
      opacity: 0.4;
    }
  }
}
</style>
